export const installationDefault = {
    identifier: "",
    location: "",
    description: "",
    phone: "",
    latitude: "",
    longitude: "",
    user: { id: "" }
}

export const trackDefault = {
    identifier: "",
    description: "",
    duration: "",
    installation: { id: "" },
    sport: { id: 1 },
    schedule: []
}

export const scheduleDefault = {
    startTime: "",
    endTime: "",
    idDay: "",
    price: "",
}

export const lockDefault = {
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: ""
}

export const dependencyDefault = {
    idDependency: "",
}

export const bookingDegault = {
    identifier: "",
    date: "",
    startTime: "",
    endTime: "",
}

export const adDefault = {
    identifier: "",
    description: "",
    link: "",
    position: 1
}

export const tournamentDefault = {
    identifier: "",
    description: "",
    location: "",
    latitude: 0,
    longitude: 0,
    startDate: "",
    sport: { id: "" }
}

export const alertDefault = {
    title: "",
    body: ""
}

export const sportList = [
    { id: 1, description: "Fútbol" },
    { id: 6, description: "Fútbol 7" },
    { id: 7, description: "Fútbol Sala" },
    { id: 2, description: "Baloncesto" },
    { id: 3, description: "Pádel" },
    { id: 4, description: "Tenis" },
    { id: 5, description: "Polideportivo" },
]

export const bookDefault = {
    identifier: "",
    user: null,
    track: null,
    price: 0,
    participants: 1,
    startTime: null
}

export const managerDefault = {
    username: "",
    phone: "",
    fullname: "",
}

export const bookingStates = [
    "Pending",
    "Paid",
    "Cancelled",
    "Penalty",
    "Notified",
    "Finished",
    "Not Paid",
    "Cash Pay",
]
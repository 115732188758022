import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { ModalViewDefault } from '../components/util/ModalUtil';
import { trackDefault } from '../constants/model';
import { TrackForm } from './TrackDetailView';

const TrackItem = ({ item, editItem, deleteItem }) => {
    return <ContainerFlex width="12" px="16" align="center">
        <Container width="wrap">
            <Container width="12" fontWeight="bold" children={item.identifier} />
            <Container width="12" fontSize="12px" children={`${item.installation.identifier}`} />
        </Container>

        <Button onClick={() => editItem(item)} px="12" mr="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionEdit" />} />
    </ContainerFlex>
}

const TracksView = () => {
    const [items, setItems] = useState([]);
    const { dispatch, state } = useContext(UserContext);
    const history = useNavigate();

    useEffect(() => {
        getItems()
    }, [])

    const getItems = () => HttpConnection(`${HttpOperations.track}/page?page=0&count=50`, result => setItems(result), error => console.log(error), HttpMethod.get);

    const editItem = (item) => history(`/track/${item.id}`);

    const addItem = () => dispatch({ action: UserAction.showModal, data: { show: true, body: <TrackForm refreshItems={getItems} item={{ ...trackDefault }} /> } });

    const deleteItem = (item) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.track}/${item.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "msgDeleteSuccess" } });
                getItems();
            },
            error => console.log(error), HttpMethod.delete)
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    return (
        <ContainerFlex width="12" align="center" justify="center" direction="column" px="16">
            <ContainerFlex width="12" lg="6" align="center">
                <Container width="wrap" fontWeight="bold" mv="16" fontSize="32px" children={<TextLocalized children="msgTrackList" />} />
                {
                    state.user.usuario.type.id === 0 ?
                        <Button onClick={addItem} px="12" color="color-black" fontWeight="500" fontSize="12px" fontColor="color-white" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionCreateInstallation" />} />
                        : null
                }
            </ContainerFlex>
            <Card width="12" lg="6" mv="8" color="color-gray-card">
                {items.map((item, index) => <TrackItem key={index} item={item} editItem={editItem} deleteItem={deleteItem} />)}
            </Card>
        </ContainerFlex>
    );
};

export default TracksView;
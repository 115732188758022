import { format, parse } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Fragment } from 'react/cjs/react.production.min';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Button, Card, Container, ContainerFlex, CustomInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { ModalViewDefault } from '../components/util/ModalUtil';
import { lockDefault, scheduleDefault, sportList } from '../constants/model';

export const TrackForm = ({ item, refreshItems }) => {
    const [formData, setFormData] = useState();
    const [installations, setInstallations] = useState([]);

    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);

    useEffect(() => {
        setFormData({ ...item })
    }, [item])

    useEffect(() => {
        HttpConnection(HttpOperations.installation,
            response => setInstallations(response),
            error => console.log(error), HttpMethod.get);
    }, [])

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSelectChange = (e) => setFormData({ ...formData, [e.target.name]: { id: e.target.value } });

    const sendRequest = () => {
        console.log(formData)
        if (formData.identifier !== "" && formData.duration !== "" && formData.sport.id !== "" && formData.installation.id !== "") {

            let formDataAux = { ...formData };
            formDataAux.description = formData.identifier;

            HttpConnection(`${HttpOperations.track}${formData.id ? `/${formData.id}` : ''}`,
                result => {
                    //Refresh data
                    refreshItems();
                    //Notify user
                    dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
                },
                error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
                formData.id ? HttpMethod.put : HttpMethod.post, formDataAux);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return <Card flex onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        {
            formData ?
                <Fragment>
                    <Container width="12" fontWeight="bold" mx="16" mb="32" fontSize="22px" children={<TextLocalized children="msgTrackTitle" />} />
                    <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgName"} placeholder={dictionary["msgName"]} type="text" name="identifier" value={formData.identifier} />} />
                    <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgIntervalSche"} placeholder={dictionary["msgIntervalSche"]} type="number" name="duration" value={formData.duration} />} />

                    <Container width="6" ph="16" pb="16">
                        <Card width="12" px="16" color="color-white" border="solid" borderWidth="1px" borderColor="color-black">
                            <select onChange={onSelectChange} name="sport" value={formData.sport.id}>
                                <option value="" children="Deporte" />
                                {sportList.map(sport => <option key={sport.id} value={sport.id} children={sport.description} />)}
                            </select>
                        </Card>
                    </Container>

                    <Container width="6" ph="16" pb="16">
                        <Card width="12" px="16" color="color-white" border="solid" borderWidth="1px" borderColor="color-black">
                            <select onChange={onSelectChange} value={formData.installation.id} name="installation">
                                <option value="" children="Instalación" />
                                {installations.map(item => <option key={item.id} value={item.id} children={item.identifier} />)}
                            </select>
                        </Card>
                    </Container>

                    <ButtonFlat width="wrap" mx="16" action={sendRequest} label="actionSave" fontColor="color-white" />
                </Fragment>
                : null
        }
    </Card>
}

const ScheduleForm = ({ item, track, refreshItems }) => {
    const [formData, setFormData] = useState({ ...item });
    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.startTime !== "" && formData.endTime !== "" && formData.idDay !== "") {

            let formDataAux = { ...formData };
            let schedulesAux = (item.id) ? track.schedule.filter(schedule => schedule.id != formData.id) : [...track.schedule];

            formDataAux.startTime = parse(formData.startTime, "HH:mm", new Date()).getTime();
            formDataAux.endTime = parse(formData.endTime, "HH:mm", new Date()).getTime();
            formDataAux.price = isNaN(parseFloat(formData.price)) ? 0 : parseFloat(formData.price).toFixed(2);

            console.log(formDataAux)

            schedulesAux.push(formDataAux)

            let trackAux = { ...track };
            trackAux.schedule = schedulesAux;

            HttpConnection(`${HttpOperations.track}/${track.id}`,
                result => {
                    //Refresh data
                    refreshItems();
                    //Notify user
                    dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
                },
                error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
                HttpMethod.put, trackAux);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return <Card flex onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        <Container width="12" fontWeight="bold" mx="16" mb="32" fontSize="22px" children={<TextLocalized children="msgSchedules" />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgHourStart"} placeholder={dictionary["msgHourHolder"]} type="text" name="startTime" value={formData.startTime} />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgHourEnd"} placeholder={dictionary["msgHourHolder"]} type="text" name="endTime" value={formData.endTime} />} />

        <Container width="6" ph="16" pb="16">
            <Card width="12" px="16" color="color-white" border="solid" borderWidth="1px" borderColor="color-black">
                <select onChange={onChange} name="idDay" value={formData.idDay}>
                    <option value="" children="Día de la semana" />
                    {["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"].map((day, index) => <option key={index} value={index + 1} children={day} />)}
                </select>
            </Card>
        </Container>

        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgPrice"} placeholder={dictionary["msgPriceHolder"]} type="text" name="price" value={formData.price} />} />

        <ContainerFlex width="12" justify="center">
            <ButtonFlat width="wrap" mx="16" action={() => sendRequest(formData)} label="actionSave" fontColor="color-white" />
        </ContainerFlex>
    </Card>
}

const ScheduleItem = ({ item, editItem, deleteItem }) => {

    const getDayDescription = (id) => {
        switch (id) {
            case 1:
                return "Domingo";
            case 2:
                return "Lunes";
            case 3:
                return "Martes";
            case 4:
                return "Miércoles";
            case 5:
                return "Jueves";
            case 6:
                return "Viernes";
            default:
                return "Sabado";
        }
    }

    const showEdit = () => {
        editItem({
            ...item,
            startTime: format(new Date(item.startTime), 'HH:mm'),
            endTime: format(new Date(item.endTime), 'HH:mm')
        })
    }

    return <ContainerFlex width="12" px="16" align="center">
        <Container width="wrap">
            <Container width="12" fontWeight="bold" children={getDayDescription(item.idDay)} />
            <Container width="12" fontSize="12px" children={`${format(new Date(item.startTime), 'HH:mm')} - ${format(new Date(item.endTime), 'HH:mm')}`} />
            <Container width="12" fontSize="12px" children={`Precio: ${item.price.toFixed(2)} €`} />

        </Container>

        <Button onClick={showEdit} px="12" mr="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionEdit" />} />
        <Button onClick={() => deleteItem(item)} px="12" ml="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionDelete" />} />
    </ContainerFlex>
}

const LockItem = ({ item, deleteItem }) => {
    return <ContainerFlex width="12" px="16" align="center">
        <Container width="wrap">
            <Container width="12" fontSize="12px" children={`${format(new Date(item.startDate), 'dd/MM/yyyy')} - ${format(new Date(item.endDate), 'dd/MM/yyyy')}`} />
            <Container width="12" fontSize="12px" children={`${format(new Date(item.startTime), 'HH:mm')} - ${format(new Date(item.endTime), 'HH:mm')}`} />
        </Container>
        <Button onClick={() => deleteItem(item)} px="12" ml="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionDelete" />} />
    </ContainerFlex>
}

const LockForm = ({ item, track, refreshItems }) => {
    const [formData, setFormData] = useState({ ...item });
    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.startTime !== "" && formData.endTime !== "" && formData.startDate !== "" && formData.endDate !== "") {

            let formDataAux = { ...formData };

            formDataAux.startTime = parse(`1970-01-01 ${formData.startTime}`, "yyyy-MM-dd HH:mm", new Date()).getTime();
            formDataAux.endTime =  parse(`1970-01-01 ${formData.endTime}`, "yyyy-MM-dd HH:mm", new Date()).getTime();

            let startDateAux = new Date(formData.startDate);
            startDateAux.setHours(0);
            startDateAux.setMinutes(0);
            startDateAux.setMilliseconds(0);

            let endDateAux = new Date(formData.endDate);
            endDateAux.setHours(0);
            endDateAux.setMinutes(0);
            endDateAux.setMilliseconds(0);

            formDataAux.startDate = startDateAux.getTime();
            formDataAux.endDate = endDateAux.getTime();

            console.log(formDataAux)

            HttpConnection(`${HttpOperations.installation}/${track.installation.id}/lock/${track.id}`,
                result => {
                    //Refresh data
                    refreshItems();
                    //Notify user
                    dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
                },
                error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
                HttpMethod.post, formDataAux);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return <Card flex onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        <Container width="12" fontWeight="bold" mx="16" mb="32" fontSize="22px" children={<TextLocalized children="msgDateIntervalBlock" />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgDateStart"} placeholder={dictionary["msgDateStart"]} type="date" name="startDate" value={formData.startDate} />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgDateEnd"} placeholder={dictionary["msgDateEnd"]} type="date" name="endDate" value={formData.endDate} />} />

        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgHourStart"} placeholder={dictionary["msgHourHolder"]} type="text" name="startTime" value={formData.startTime} />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgHourEnd"} placeholder={dictionary["msgHourHolder"]} type="text" name="endTime" value={formData.endTime} />} />

        <ContainerFlex width="12" justify="center">
            <ButtonFlat width="wrap" mx="16" action={() => sendRequest(formData)} label="actionSave" fontColor="color-white" />
        </ContainerFlex>
    </Card>
}

const DependecyItem = ({ item, deleteItem }) => {

    const [trackInfo, setTrackInfo] = useState();

    useEffect(() => {
        HttpConnection(`${HttpOperations.track}/${item.idDependency}`, response => setTrackInfo(response), error => console.log(error), HttpMethod.get);
    },[item])

    return <ContainerFlex width="12" px="16" align="center">
        <Container width="wrap">
            <Container width="12" fontWeight="bold" children={`${trackInfo ? trackInfo.identifier : ""}`} />
        </Container>
        <Button onClick={() => deleteItem(item)} px="12" ml="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionDelete" />} />
    </ContainerFlex>
}

const DependencyForm = ({ item, track, refreshItems }) => {
    const [formData, setFormData] = useState({ ...item });
    const [tracks, setTracks] = useState([]);
    const { dispatch } = useContext(UserContext);

    useEffect(() => {
        HttpConnection(`${HttpOperations.track}/page?page=0&count=50`, response => {
            setTracks(response.filter(trackDepen => trackDepen.installation.id === track.installation.id));
        }, error => console.log(error), HttpMethod.get);
    }, [])

    const sendRequest = () => {
        if (formData.idDependency !== "") {
            HttpConnection(`${HttpOperations.installation}/${track.installation.id}/dependency/${track.id}`,
                result => {
                    //Refresh data
                    refreshItems();
                    //Notify user
                    dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
                },
                error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
                HttpMethod.post, formData);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return <Card flex onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        <Container width="12" fontWeight="bold" mx="16" mb="32" fontSize="22px" children={<TextLocalized children="msgTracksDependencies" />} />
        <ContainerFlex width="12" ph="16" pb="16">
            <Card width="12" px="16" color={formData.idDependency === "" ? "color-gray" : "color-white"} border="solid" borderWidth="1px" borderColor="color-black">
                <select onChange={e => setFormData({ ...formData, idDependency: e.target.value })} name="idDependency" value={formData.idDependency ?? ""}>
                    <option value="" children="Pista dependiente" />
                    {tracks.map(trackDepen => <option key={trackDepen.id} value={trackDepen.id} children={`${trackDepen.identifier}`} />)}
                </select>
            </Card>
        </ContainerFlex>

        <ContainerFlex width="12" justify="center">
            <ButtonFlat width="wrap" mx="16" action={() => sendRequest()} label="actionSave" fontColor="color-white" />
        </ContainerFlex>
    </Card>
}

const TrackDetailView = () => {
    const [item, setItem] = useState();
    const [lockDates, setLockDates] = useState([]);
    const [dependencies, setDependencies] = useState([]);

    const { dispatch } = useContext(UserContext);

    const params = useParams();

    useEffect(() => getItems(), [])

    useEffect(() => {
        if (item) {
            getLockDates();
            getDependencies();
        }
    }, [item])

    const getItems = () => HttpConnection(`${HttpOperations.track}/${params.id}`, result => setItem(result), error => console.log(error), HttpMethod.get);
    const getLockDates = () => HttpConnection(`${HttpOperations.installation}/${item.installation.id}/lock/${params.id}`, result => setLockDates(result), error => console.log(error), HttpMethod.get);
    const getDependencies = () => HttpConnection(`${HttpOperations.installation}/${item.installation.id}/dependency/${params.id}`, result => setDependencies(result), error => console.log(error), HttpMethod.get);

    const editItem = (schedule) => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ScheduleForm track={item} refreshItems={getItems} item={schedule} /> } });
    }

    const addItem = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ScheduleForm track={item} refreshItems={getItems} item={{ ...scheduleDefault }} /> } });
    }

    const addLockItem = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <LockForm track={item} refreshItems={getLockDates} item={{ ...lockDefault }} /> } });
    }

    const addDependencyItem = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <DependencyForm track={item} refreshItems={getDependencies} item={{ ...lockDefault }} /> } });
    }

    const deleteItem = (scheduleItem) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });

        let trackAux = {...item};
        trackAux.schedule = item.schedule.filter(schedule => schedule.id != scheduleItem.id);

        let actionPositive = () => HttpConnection(`${HttpOperations.track}/${item.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "msgDeleteSuccess" } });
                getItems();
            },
            error => console.log(error), HttpMethod.put, trackAux)
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    const deleteLockItem = (lock) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.installation}/${item.installation.id}/lock/${lock.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "msgDeleteSuccess" } });
                getItems();
            },
            error => console.log(error), HttpMethod.delete)
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    const deleteDependecyItem = (dependency) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.installation}/${item.installation.id}/dependency/${dependency.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "msgDeleteSuccess" } });
                getItems();
            },
            error => console.log(error), HttpMethod.delete)
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    return (
        <ContainerFlex width="12" align="center" justify="center" direction="column" px="16">
            {
                item ?
                    <Fragment>
                        <TrackForm item={item} refreshItems={getItems} />

                        <ContainerFlex width="12" lg="6">

                            <Container width="12">
                                <ContainerFlex width="12" align="center">
                                    <Container width="wrap" fontWeight="bold" mv="16" fontSize="32px" children={<TextLocalized children="msgTracksDependencies" />} />
                                </ContainerFlex>
                                <Card width="12" mv="8" color="color-gray-card">
                                    {dependencies.map((item, index) => <DependecyItem key={index} item={item} deleteItem={deleteDependecyItem} />)}
                                </Card>
                                <Button onClick={addDependencyItem} px="12" color="color-black" textAlign="center" fontWeight="500" fontSize="12px" fontColor="color-white" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionCreateInstallation" />} />
                            </Container>

                            <Container width="12" lg="6" pr="8">
                                <ContainerFlex width="12" align="center">
                                    <Container width="wrap" fontWeight="bold" mv="16" fontSize="32px" children={<TextLocalized children="msgSchedules" />} />
                                </ContainerFlex>
                                <Card width="12" mv="8" color="color-gray-card">
                                    {item ? item.schedule.sort((a, b) => (a.idDay == 1 ? 10 : a.idDay) - (b.idDay == 1 ? 10 : b.idDay)).map((item, index) => <ScheduleItem key={index} item={item} editItem={editItem} deleteItem={deleteItem} />) : null}
                                </Card>
                                <Button onClick={addItem} px="12" color="color-black" textAlign="center" fontWeight="500" fontSize="12px" fontColor="color-white" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionCreateInstallation" />} />
                            </Container>

                            <Container width="12" lg="6" pl="8">
                                <ContainerFlex width="12" align="center">
                                    <Container width="wrap" fontWeight="bold" mv="16" fontSize="32px" children={<TextLocalized children="msgDateIntervalBlock" />} />
                                </ContainerFlex>
                                <Card width="12" mv="8" color="color-gray-card">
                                    {lockDates.map((item, index) => <LockItem key={index} item={item} deleteItem={deleteLockItem} />)}
                                </Card>
                                <Button onClick={addLockItem} px="12" color="color-black" textAlign="center" fontWeight="500" fontSize="12px" fontColor="color-white" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionCreateInstallation" />} />
                            </Container>

                        </ContainerFlex>
                    </Fragment>
                    : null
            }
        </ContainerFlex>
    );
};

export default TrackDetailView;
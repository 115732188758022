const es = {
    appName: "ReseSport Manager",
    actionLogin: "INGRESAR A MI CUENTA",
    actionContinue: "CONTINUAR",
    actionConfirmCon: "CONFIRMAR Y CONTINUAR",
    actionUpdate: "ACTUALIZAR",
    actionEdit: "EDITAR",
    actionDelete: "ELIMINAR",
    actionBack: "VOLVER",
    actionSave: "GUARDAR",
    actionYes: "Sí",
    actionNo: "No",
    actionAdd: "AGREGAR",
    actionCancel: "CANCELAR",
    actionAccept: "ACEPTAR",
    actionSelect: "SELECCIONAR",
    actionFilter: "FILTRAR",

    actionCreateInstallation: "CREAR NUEVA",
    actionCreateManager: "CREAR NUEVO",

    actionAddAttached: "AÑADIR ADJUNTO",
    actionAddClient: "ALTA NUEVO CLIENTE",
    actionEditDetails: "EDITAR DETALLES",
    actionShowEdit: "VER/EDITAR",
    actionAddEmployee: "ALTA NUEVO TRABAJADOR",
    actionAddTraining: "AÑADIR FORMACIÓN",
    actionShowAll: "VER TODOS",
    actionCreate: "Crear",
    actionShowAttached: "VER ADJUNTO",
    actionNew: "NUEVO",
    actionShow: "VER",
    actionExit: "Salir",
    actionFilter: "FILTRAR",
    actionClose: "CERRAR",

    msgEmail: "Email",
    msgPassword: "Contraseña",
    msgForget: "¿Has olvidado tu contraseña?",

    /* HOME */
    msgInstallations: "Instalaciones",
    msgTracks: "Pistas",
    msgBoolks: "Reservas",
    msgProducts: "Productos",
    msgTournaments: "Torneos",
    msgNotifications: "Notificaciones",
    msgUsers: "Usuarios",

    msgSelectFile: "Selecciona la imagen asociada",

    msgName: "Nombre",
    msgDescription: "Descripción",
    msgAddress: "Dirección",
    msgPhone: "Teléfono",
    msgTitle: "Título",
    msgLink: "Enlace",
    msgDate: "Fecha",
    msgLatitude: "Latitud",
    msgLongitude: "Longitud",

    /* INSTALLATIONS */
    msgInstallationTitle: "Crea/edita instalaciones en la plataforma",
    msgInstallationList: "Listado de instalaciones",

    /* TRACKS */
    msgTrackTitle: "Crea/edita pistas en la plataforma",
    msgTrackList: "Listado de pistas",

    msgIntervalSche: "Intervalo horario",
    msgDateIntervalBlock: "Bloqueados",
    msgTracksDependencies: "Dependencias de pista",

    msgSchedules: "Horarios",

    msgDateStart: "Fecha inicio",
    msgDateEnd: "Fecha fin",
    msgHourStart: "Hora inicio",
    msgHourEnd: "Hora fin",
    msgHourHolder: "HH:mm",
    msgPrice: "Precio/tramo horario",
    msgPriceHolder: "0.00",

    /* BOOKS */
    msgBooks: "Reservas",
    msgBookList: "Listado de reservas",

    msgBookTitle: "Alta de reservas",
    msgBookFilter: "Filtros de busqueda",
    msgBookData: "Datos de la reserva",
    msgUserPhone: "Teléfono del usuario",
    msgBookDate: "Fecha (dd/MM/yyyy)",
    msgBookTime: "Hora (HH:mm)",

    /* MANAGERS */
    msgManagers: "Responsables",
    msgManagersList: "Listado de responsables",
    msgManagerTitle: "Crea/edita responsables en la plataforma",

    /* PRODUCTS */
    msgProductTitle: "Crea/edita productos en la plataforma",
    msgProductList: "Listado de productos",
    msgCompleteHere: "Completa aquí",

    /* TOURNAMENTS */
    msgTournamentTitle: "Crea/edita torneos en la plataforma",
    msgTournamentList: "Listado de torneos",

    /*NOTIFICATIONS */
    msgNotificationTitle: "Crea/edita notificaciones en la plataforma",
    msgNotificationList: "Listado de notificaciones",

    /* USERS */
    msgUserList: "Listado de usuarios",
    msgUserInfo: "Información de usuario",
    msgWallet: "Monedero",
    msgBalance: "Saldo actual",
    msgAmount: "Cantidad a modificar",

    /* MODAL */
    msgAreSure: "¿Estás seguro?",

    msgSuccess: "Datos actualizados correctamente",
    msgDeleteSuccess: "Datos eliminados correctamente",

    errorCode: "El código introducido no es correcto",
    errorValidate: "Error al enviar usuario",
    errorOblData: "Faltan datos obligatorios",
    errorEmailFormat: "Formato de email incorrecto",
    errorDNIRegister: "Ya existe una cuenta con este DNI/CIF",
    errorUserRecjected: "Su usuario ha sido rechazado, contacte con la asociación",
    errorUserIncorrect: "Email/contraseña incorrectos",
    errorSend: "Error al enviar",
    errorDelete: "El usuario no puede ser eliminado",
}

export default es;
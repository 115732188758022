import './css/normalize.css';
import './css/bootstrap-grid.css';
import './css/App.css';

import { LanguageProvider } from './components/providers/LanguageProvider';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginView from './views/LoginView';
import { Fragment, useContext } from 'react';
import { NotificationView } from './components/util/NotificationUtil';
import ModalView from './components/util/ModalUtil';
import { Container } from './components/styled/Styled';
import Header from './components/Header';
import { UserContext, UserProvider } from './components/providers/UserProvider';
import InstallationsView from './views/InstallationsView';
import TournamentsView from './views/TournamentsView';
import ProductsView from './views/ProductsView';
import NotificationsView from './views/NotificationsView';
import TracksView from './views/TracksView';
import TrackDetailView from './views/TrackDetailView';
import BooksView from './views/BooksView';
import ManagersView from './views/ManagersView';
import UsersView from './views/UsersView';

const SwitchView = () => {
  const { state } = useContext(UserContext);

  return <Fragment>
    <Container width="12" minheight="100vh" color="color-white">
      <Router>
        {
          state.login ?
            <Fragment>
              <Header />
              <Container width="12" height="calc(100vh - 54px)" overflowY="auto" color="color-white" >
                <Routes>
                  <Route exact path="/" element={<InstallationsView />} />
                  <Route exact path="/tracks" element={<TracksView />} />
                  <Route exact path="/track/:id" element={<TrackDetailView />} />
                  <Route exact path="/books" element={<BooksView />} />
                  {
                    state.user.usuario.type.id === 0 ?
                      <Fragment>
                        <Route exact path="/users" element={<UsersView />} />
                        <Route exact path="/managers" element={<ManagersView />} />
                        <Route exact path="/tournaments" element={<TournamentsView />} />
                        <Route exact path="/products" element={<ProductsView />} />
                        <Route exact path="/notifications" element={<NotificationsView />} />
                      </Fragment>
                      : null
                  }
                  <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
              </Container>
            </Fragment>
            :
            <Routes>
              <Route exact path="/" element={<LoginView />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
        }
      </Router>
      {state.notification.show ? <NotificationView /> : null}
    </Container>
    {state.modal.show ? <ModalView children={state.modal.body} /> : null}
  </Fragment>
}

const App = () => <LanguageProvider children={<UserProvider children={<SwitchView />} />} />


export default App;
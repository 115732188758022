import React, { useContext, useEffect, useState } from 'react';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Button, Card, Container, ContainerFlex, CustomInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { ModalViewDefault } from '../components/util/ModalUtil';
import { managerDefault } from '../constants/model';

const ManagerForm = ({ item, refreshItems }) => {
    const [formData, setFormData] = useState({ ...item });
    const [isSending, setIsSending] = useState(false);

    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    useEffect(() => {
        if (item.id)
            HttpConnection(`${HttpOperations.installation}/${item.id}`, result => setFormData(result), error => console.log(error), HttpMethod.get);
    }, [item])

    const sendRequest = () => {
        //Post item
        if (formData.username !== "" && formData.fullname !== "" && formData.phone !== "") {
            let formDataAux = { ...formData };
            formDataAux.nick = formData.fullname;

            HttpConnection(`${HttpOperations.user}/responsable${formData.id ? `/${formData.id}` : ''}`,
                result => resultSuccess(),
                error => {
                    console.log(error)
                    setIsSending(false);
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } })
                }, formData.id ? HttpMethod.put : HttpMethod.post, formDataAux);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    const resultSuccess = () => {
        //Refresh data
        refreshItems();
        //Notify user
        dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
    }

    return <Card flex onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        <Container width="12" mb="16" fontWeight="bold" mx="16" fontSize="22px" children={<TextLocalized children="msgManagerTitle" />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgEmail"} placeholder={dictionary["msgEmail"]} type="title" name="username" value={formData.username} />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgPhone"} placeholder={dictionary["msgPhone"]} type="title" name="phone" value={formData.phone} />} />
        <Container width="12" ph="16" pb="16" mt="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgName"} placeholder={dictionary["msgName"]} type="title" name="fullname" value={formData.fullname} />} />
        <ButtonFlat isSending={isSending} width="wrap" mx="16" action={sendRequest} label="actionSave" fontColor="color-white" />
    </Card>
}

const ManagerItem = ({ item, editItem, deleteItem }) => {
    return <ContainerFlex width="12" px="16" align="center">
        <Container width="wrap">
            <Container width="12" fontSize="12px" children={item.username} />
            <Container width="12" fontSize="12px" children={item.fullname} />
            <Container width="12" fontSize="12px" children={item.phone} />
        </Container>

        <Button onClick={() => editItem(item)} px="12" mr="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionEdit" />} />
        <Button onClick={() => deleteItem(item)} px="12" ml="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionDelete" />} />
    </ContainerFlex>
}

const ManagersView = () => {
    const [items, setItems] = useState([]);
    const { dispatch } = useContext(UserContext);

    useEffect(() => getItems(), [])

    const getItems = () => HttpConnection(`${HttpOperations.user}/page?page=0&count=100&columns=type&values=1`,
        result => setItems(result), error => console.log(error), HttpMethod.get);

    const editItem = (item) => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ManagerForm refreshItems={getItems} item={item} /> } });
    }

    const addItem = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ManagerForm refreshItems={getItems} item={{ ...managerDefault }} /> } });
    }

    const deleteItem = (item) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.user}/${item.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "msgDeleteSuccess" } });
                getItems();
            },
            error => console.log(error), HttpMethod.delete)
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    return (
        <ContainerFlex width="12" align="center" justify="center" direction="column" px="16">
            <ContainerFlex width="12" lg="6" align="center">
                <Container width="wrap" fontWeight="bold" mv="16" fontSize="32px" children={<TextLocalized children="msgManagersList" />} />
                <Button onClick={addItem} px="12" color="color-black" fontWeight="500" fontSize="12px" fontColor="color-white" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionCreateManager" />} />
            </ContainerFlex>
            <Card width="12" lg="6" mv="8" color="color-gray-card">
                {items.map((item, index) => <ManagerItem key={index} item={item} editItem={editItem} deleteItem={deleteItem} />)}
            </Card>
        </ContainerFlex>
    );
};

export default ManagersView;
import React, { useContext, useEffect, useRef, useState } from 'react';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { AreaInput, Button, Card, Container, ContainerFlex, CustomInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpFormData, HttpImage, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { ModalViewDefault } from '../components/util/ModalUtil';
import { installationDefault } from '../constants/model';

const InstallationForm = ({ item, refreshItems }) => {
    const [formData, setFormData] = useState({ ...item });
    const [managers, setManagers] = useState([]);
    const [attach, setAttach] = useState();

    const [isSending, setIsSending] = useState(false);

    const { dictionary } = useContext(LanguageContext);
    const { dispatch, state } = useContext(UserContext);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
    const inputRef = useRef();

    useEffect(() => {
        //Get installation details
        if (item.id)
            HttpConnection(`${HttpOperations.installation}/${item.id}`, result => setFormData(result), error => console.log(error), HttpMethod.get);
        //Get managers
        if (state.user.usuario.type.id === 0)
            HttpConnection(`${HttpOperations.user}/page?page=0&count=100&columns=type&values=1`,
                result => setManagers(result), error => console.log(error), HttpMethod.get);
        else setManagers([state.user.usuario])
    }, [item])

    const sendRequest = () => {
        //Post item
        if (formData.identifier !== "" && formData.location !== "" && formData.description) {
            let formDataAux = { ...formData };
            //TODO Geocoder location
            HttpConnection(`${HttpOperations.installation}${formData.id ? `/${formData.id}` : ''}`,
                result => {
                    if (attach) {
                        sendImage(result)
                    } else {
                        resultSuccess()
                    }
                },
                error => {
                    console.log(error)
                    setIsSending(false);
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } })
                }, formData.id ? HttpMethod.put : HttpMethod.post, formDataAux);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    const sendImage = (item) => {
        HttpFormData(`${HttpOperations.installation}/${item.id}/image`,
            result => resultSuccess(),
            error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
            HttpMethod.post, formData, [attach]);
    }

    const resultSuccess = () => {
        //Refresh data
        refreshItems();
        //Notify user
        dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
    }

    const handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var image = new Image();
            image.src = URL.createObjectURL(file);

            image.onload = () => {
                URL.revokeObjectURL(image.src);
                setAttach({ file: file, url: reader.result });
            };
        }

        if (file !== null)
            reader.readAsDataURL(file)
    }

    const deleteAttach = () => {
        setAttach(null);
        setFormData({ ...formData, file: null, })
    }

    return <Card flex onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        <Container width="12" fontWeight="bold" mx="16" fontSize="22px" children={<TextLocalized children="msgInstallationTitle" />} />
        <Container width="12" ph="16" pb="16" mt="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgName"} placeholder={dictionary["msgName"]} type="text" name="identifier" value={formData.identifier} />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgAddress"} placeholder={dictionary["msgAddress"]} type="address" name="location" value={formData.location} />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgPhone"} placeholder={dictionary["msgPhone"]} type="phone" name="phone" value={formData.phone ?? ""} />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgLatitude"} placeholder={dictionary["msgLatitude"]} type="text" name="latitude" value={formData.latitude} />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgLongitude"} placeholder={dictionary["msgLongitude"]} type="text" name="longitude" value={formData.longitude ?? ""} />} />
        <Container width="12" ph="16" pb="16" children={<AreaInput onChange={onChange} titleph="16" title={"msgDescription"} placeholder={dictionary["msgDescription"]} type="text" name="description" value={formData.description ?? ""} />} />

        <ContainerFlex width="12" ph="16" pb="16">
            <Card disabled={state.user.usuario.type.id !== 0} width="12" px="16" color={formData.user.id === "" ? "color-gray" : "color-white"} border="solid" borderWidth="1px" borderColor="color-black">
                <select onChange={e => setFormData({ ...formData, user: { id: e.target.value } })} name="user" value={formData.user.id ?? ""}>
                    <option value="" children="Responsable" />
                    {managers.map(manager => <option key={manager.id} value={manager.id} children={`${manager.fullname} - ${manager.phone}`} />)}
                </select>
            </Card>
        </ContainerFlex>
        <ContainerFlex width="12" ph="16" align="center">
            <Container pv="16" children={<TextLocalized children="msgSelectFile" />} />
            {
                formData.file || attach
                    ? <Button onClick={deleteAttach} mh="16" ph="16" pv="8" justify="center" align="center" color="color-black" fontColor="color-white" letterSpacing="1px" children={<TextLocalized children="actionDelete" />} />
                    : <Button onClick={() => inputRef.current.click()} mh="16" ph="16" pv="8" justify="center" align="center" color="color-white" fontColor="color-black" letterSpacing="1px" children={<TextLocalized children="actionSelect" />} />
            }
            <input onChange={handleImageChange} ref={inputRef} hidden type="file" />
        </ContainerFlex>
        <ButtonFlat isSending={isSending} width="wrap" mx="16" action={sendRequest} label="actionSave" fontColor="color-white" />
    </Card>
}

const InstallationItem = ({ item, showItem, editItem, deleteItem }) => {
    return <ContainerFlex width="12" px="16" align="center">
        <Container width="wrap">
            <Container width="12" fontWeight="bold" children={item.identifier} />
            <Container width="12" fontSize="12px" children={item.location} />
            <Container width="12" fontSize="12px" children={item.phone} />
        </Container>

        <Button onClick={() => showItem(item)} px="12" mr="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionShow" />} />
        <Button onClick={() => editItem(item)} px="12" mr="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionEdit" />} />
        <Button onClick={() => deleteItem(item)} px="12" ml="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionDelete" />} />
    </ContainerFlex>
}

const InstallationsView = () => {
    const [items, setItems] = useState([]);
    const { dispatch, state } = useContext(UserContext);

    useEffect(() => getItems(), [])

    const getItems = () => HttpConnection(`${HttpOperations.installation}/page?page=0&count=50`, result => setItems(result), error => console.log(error), HttpMethod.get);

    const showItem = (item) => {
        if (item.file)
            HttpImage(`${HttpOperations.file}/${item.file.id}/file`,
                result => dispatch({ action: UserAction.showModal, data: { show: true, body: <img alt="document" height="95%" src={result} /> } }),
                error => console.log(error));
    }

    const editItem = (item) => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <InstallationForm refreshItems={getItems} item={item} /> } });
    }

    const addItem = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <InstallationForm refreshItems={getItems} item={{ ...installationDefault }} /> } });
    }

    const deleteItem = (item) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.installation}/${item.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "msgDeleteSuccess" } });
                getItems();
            },
            error => console.log(error), HttpMethod.delete)
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    return (
        <ContainerFlex width="12" align="center" justify="center" direction="column" px="16">
            <ContainerFlex width="12" lg="6" align="center">
                <Container width="wrap" fontWeight="bold" mv="16" fontSize="32px" children={<TextLocalized children="msgInstallationList" />} />
                {
                    state.user.usuario.type.id === 0 ?
                        <Button onClick={addItem} px="12" color="color-black" fontWeight="500" fontSize="12px" fontColor="color-white" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionCreateInstallation" />} />
                        : null
                }
            </ContainerFlex>
            <Card width="12" lg="6" mv="8" color="color-gray-card">
                {items.map((item, index) => <InstallationItem key={index} item={item} showItem={showItem} editItem={editItem} deleteItem={deleteItem} />)}
            </Card>
        </ContainerFlex>
    );
};

export default InstallationsView;
import React, { useContext, useEffect, useState } from 'react';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Button, Card, Container, ContainerFlex, CustomInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { ModalViewDefault } from '../components/util/ModalUtil';

const UserForm = ({ item }) => {

    const [amountChange, setAmountChange] = useState("");
    const [amount, setAmount] = useState(0);
    const [isSending, setIsSending] = useState(false);

    const { dispatch } = useContext(UserContext);
    const { dictionary } = useContext(LanguageContext);

    useEffect(() => {
        //console.log(item);
        getBalance();
    }, [])

    const getBalance = () => HttpConnection(`${HttpOperations.card}/balance/${item.id}`, response => {
        setAmount(response.amount);
    }, error => {
        console.log(error);
    }, HttpMethod.get)

    const sendRequest = () => {
        if (amountChange !== "") {
            setIsSending(true);
            HttpConnection(`${HttpOperations.card}/refund/${item.id}?amount=${amountChange}`, response => {
                setIsSending(false);
                getBalance()
            }, error => {
                setIsSending(false);
                console.log(error);
            }, HttpMethod.patch)
        }
    }

    const closeModal = () => {
        dispatch({ action: UserAction.showModal, data: { show: false } })
    }

    return <Card flex onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        <Container width="12" fontWeight="bold" mx="16" fontSize="22px" children={<TextLocalized children="msgUserInfo" />} />
        <Container width="12" ph="16" pb="16" mt="16" children={<CustomInput disabled titleph="16" title={"msgName"} placeholder={dictionary["msgName"]} type="text" name="fullname" value={item.fullname} />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput disabled titleph="16" title={"msgAddress"} placeholder={dictionary["msgEmail"]} type="text" name="username" value={item.username} />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput disabled titleph="16" title={"msgPhone"} placeholder={dictionary["msgPhone"]} type="phone" name="phone" value={item.phone ?? ""} />} />

        <Container width="12" fontWeight="bold" mx="16" fontSize="22px" children={<TextLocalized children="msgWallet" />} />
        <Container width="12" ph="16" pb="16" mt="16" children={<CustomInput disabled titleph="16" title={"msgBalance"} placeholder={dictionary["msgBalance"]} type="text" name="fullname" value={amount} />} />

        <Container width="12" ph="16" pb="16"
            children={<CustomInput onChange={e => setAmountChange(e.target.value)} width="wrap" titlemh="16" inputmr="8" title={"msgAmount"} placeholder={dictionary["msgAmount"]} type="number" name="amount" value={amountChange} />} />

        <ContainerFlex width="12" ph="16" pb="16" mt="16" align="stretch">
            <ButtonFlat action={closeModal} width="wrap" mr="16" label="actionClose" color='color-red' fontColor="color-white" />
            <ButtonFlat isSending={isSending} width="wrap" ph="16" action={sendRequest} label="actionUpdate" color='color-black' fontColor="color-white" />
        </ContainerFlex>
    </Card>
}

const UserItem = ({ item, editItem, deleteItem }) => {
    return <ContainerFlex width="12" px="16" align="center">
        <Container width="wrap">
            <Container width="12" fontWeight="bold" children={item.fullname} />
            <Container width="12" fontSize="12px" children={item.username} />
            <Container width="12" fontSize="12px" children={item.phone} />
        </Container>

        <Button onClick={() => editItem(item)} px="12" mr="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionEdit" />} />
        <Button onClick={() => deleteItem(item)} px="12" ml="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionDelete" />} />
    </ContainerFlex>
}

const UsersView = () => {
    const [formFilter, setFormFilter] = useState({ username: "", fullname: "" });
    const [items, setItems] = useState([]);
    const { dispatch, state } = useContext(UserContext);
    const { dictionary } = useContext(LanguageContext);

    useEffect(() => getItems(), [])

    const getItems = () => HttpConnection(`${HttpOperations.user}/page?page=0&count=50&columns=username,fullname&values=${formFilter.username},${formFilter.fullname}`, result => setItems(result), error => console.log(error), HttpMethod.get);

    const editItem = (item) => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <UserForm refreshItems={getItems} item={item} /> } });
    }

    const deleteItem = (item) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.user}/${item.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "msgDeleteSuccess" } });
                getItems();
            },
            error => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorDelete" } });
            }, HttpMethod.delete)
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    const onChange = e => setFormFilter({ ...formFilter, [e.target.name]: e.target.value });

    return (
        <ContainerFlex width="12" align="center" justify="center" direction="column" px="16">
            <ContainerFlex width="12" lg="6" align="center">
                <Container width="wrap" fontWeight="bold" mv="16" fontSize="32px" children={<TextLocalized children="msgUserList" />} />
            </ContainerFlex>
            <ContainerFlex width="12" lg="6" align="stretch">
                <CustomInput onChange={onChange} width="wrap" titlemh="16" inputmr="8" title={"msgEmail"} placeholder={dictionary["msgEmail"]} type="text" name="username" value={formFilter.username} />
                <CustomInput onChange={onChange} width="wrap" titlemh="16" inputmr="8" title={"msgName"} placeholder={dictionary["msgName"]} type="text" name="fullname" value={formFilter.fullname} />

                <Button flex align="center" onClick={getItems} pv="12" ph="32" color="color-black" fontWeight="500" fontSize="12px" fontColor="color-white" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionFilter" />} />

            </ContainerFlex>
            <Card width="12" lg="6" mv="8" color="color-gray-card">
                {items.map((item, index) => <UserItem key={index} item={item} editItem={editItem} deleteItem={deleteItem} />)}
            </Card>
        </ContainerFlex>
    );
};

export default UsersView;
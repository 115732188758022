import { getUserToken, getStorageUser, saveStorageUser } from '../util/UserUtil';

export const HttpOperations = {
    login: "auth/token",
    code: "auth/code",

    installation: "installation",
    booking: "booking",
    track: "track",
    ad: "ad",
    tournament: "tournament",
    alert: "alert",
    file: "file",
    user: "user",
    card: "card",
}

export const HttpMethod = {
    get: "GET",
    post: "POST",
    patch: "PATCH",
    delete: "DELETE",
    put: "PUT"
}

export const HttpConnection = async (operation, success, error, method = "GET", data = null, checkingAuth = false) => {
    let token = getUserToken();

    const request = await fetch(`${serverUrlEndpoint}${operation}`, {
        method: method,
        body: data === null ? null : JSON.stringify(data),
        headers: {
            'Authorization': `${token === null || checkingAuth ? '' : token}`,
            'Content-Type': 'application/json',
        }
    })

    let item = Object()

    if (!request.ok) {
        if ((request.status === 401 && !checkingAuth) || (!checkingAuth && token === "" && getStorageUser())) {
            var auxUser = getStorageUser();
            const params = {
                username: auxUser.usuario.username,
                password: auxUser.usuario.password,
            }

            HttpConnection(HttpOperations.login, (item) => {
                //Set clear password
                item.usuario.password = auxUser.usuario.password
                saveStorageUser(item);
                HttpConnection(operation, success, error, method, data, false);
            }, (errorCode) => error(errorCode), HttpMethod.post, params, true);
        } else {
            try {
                item = await request.json();
            } catch (error) {
                item = request.status;
            }
            error(item);
        }
    } else {
        try {
            item = await request.json();
        } catch (error) {
            item = {};
        }
        success(item);
    }
}

export const HttpAuthFetch = async (operation, method = "GET", data = null) => {
    let token = getUserToken();

    return fetch(`${serverUrlEndpoint}${operation}`, {
        method: method,
        body: data === null ? null : JSON.stringify(data),
        headers: {
            'Authorization': `${token === null ? '' : token}`,
            'Content-Type': 'application/json',
        }
    })
}

export const HttpFormData = async (operation, success, error, method = "POST", data, files = [], checkingAuth = false) => {
    let token = getUserToken();

    console.log(token)
    console.log(JSON.stringify(data))

    //Set formData values
    var formData = new FormData()
    if (files.length > 0)
        formData.append("file", files[0].file, `resesport-${new Date().getTime()}.${files[0].extension ?? "png"}`);

    if (data)
        formData.append("data", JSON.stringify(data));

    const request = await fetch(`${serverUrlEndpoint}${operation}`, {
        method: method,
        body: formData,
        headers: { 'Authorization': `${token === null || checkingAuth ? '' : token}` },
    })

    let item = Object()

    if (!request.ok) {
        if ((request.status === 401 && !checkingAuth) || (!checkingAuth && token === "")) {
            var auxUser = getStorageUser();
            const params = {
                username: auxUser.usuario.username,
                password: auxUser.usuario.password,
            }

            HttpConnection(HttpOperations.login, (item) => {
                //Set clear password
                item.usuario.password = auxUser.usuario.password
                saveStorageUser(item);
                HttpFormData(operation, success, error, HttpMethod.post, data, files, false);
            }, (errorCode) => error(errorCode), HttpMethod.post, params, true);

        } else {
            error(request.status);
            console.log(request.status);
        }
    } else {
        try {
            item = await request.json();
        } catch (error) {
            item = {};
            console.log(error)
        }
        success(item);
    }
}

export const HttpImage = async (operation, success, error, checkingAuth = false) => {
    let token = getUserToken();

    const request = await fetch(`${serverUrlEndpoint}${operation}`, {
        method: HttpMethod.get,
        headers: { 'Authorization': `${token === null || checkingAuth ? '' : token}`, },
    })

    if (!request.ok) {
        if ((request.status === 401 && !checkingAuth) || (!checkingAuth && token === "" && getStorageUser())) {
            var auxUser = getStorageUser();
            const params = {
                username: auxUser.usuario.username,
                password: auxUser.usuario.password,
            }

            HttpConnection(HttpOperations.login, (item) => {
                //Set clear password
                item.usuario.password = auxUser.usuario.password
                saveStorageUser(item);
                HttpImage(operation, success, error, true);
            }, (errorCode) => error(errorCode), HttpMethod.post, params, true);
        } else {
            error(request.status);
            console.log(request.status);
        }
    } else {
        let item = Object()
        try {
            let buffer = await request.arrayBuffer();
            item = `data:${"image/jpeg"};base64,${new Buffer(buffer).toString('base64')}`;
        } catch (error) {
            item = {};
            console.log(error)
        }
        success(item);
    }
}

const debugMode = false;
//movil: 172.20.10.8
//wifi: 192.168.5.38

//"https://hubiqus.es/resesport/api/"
//https://api.resesport.es/resesport/api/
export const serverUrlEndpoint = debugMode ? "http://192.168.5.39:8084/resesport/api/" : "https://api.resesport.es/resesport/api/";
export const imageURL = debugMode ? "http://192.168.5.39:8084/resesport/api/" : "https://api.resesport.es/resesport/api/";

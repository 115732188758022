import { format } from 'date-fns';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { AreaInput, Button, Card, Container, ContainerFlex, CustomInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpFormData, HttpImage, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { ModalViewDefault } from '../components/util/ModalUtil';
import { sportList, tournamentDefault } from '../constants/model';

const TournamentForm = ({ item, refreshItems }) => {
    const [formData, setFormData] = useState({ ...item });
    const [attach, setAttach] = useState();
    const [isSending, setIsSending] = useState(false);

    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSelectChange = (e) => setFormData({ ...formData, [e.target.name]: { id: e.target.value } });

    const inputRef = useRef();

    useEffect(() => {
        if (item.id)
            HttpConnection(`${HttpOperations.tournament}/${item.id}`, response => setFormData(response), error => console.log(error), HttpMethod.get);
    }, [item])

    const sendRequest = () => {
        //Post item
        if (formData.identifier !== "" && formData.description !== "" && formData.location !== "" && formData.startDate !== "") {
            let formDataAux = { ...formData };
            formDataAux.startDate = new Date(formData.startDate).getTime();

            setIsSending(true);

            if (attach) {
                HttpFormData(`${HttpOperations.tournament}${formData.id ? `/${formData.id}` : ''}/form`,
                    result => successResponse(),
                    error => errorResponse(),
                    formData.id ? HttpMethod.put : HttpMethod.post, formDataAux, attach ? [attach] : []);
            } else {
                HttpConnection(`${HttpOperations.tournament}${formData.id ? `/${formData.id}` : ''}`,
                    result => successResponse(),
                    error => errorResponse(),
                    formData.id ? HttpMethod.put : HttpMethod.post, formDataAux);
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    const successResponse = () => {
        //Refresh data
        refreshItems();
        //Notify user
        dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
    }

    const errorResponse = () => {
        setIsSending(false);
        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } })
    }

    const handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var image = new Image();
            image.src = URL.createObjectURL(file);

            image.onload = () => {
                URL.revokeObjectURL(image.src);
                setAttach({ file: file, url: reader.result });
            };
        }

        if (file !== null)
            reader.readAsDataURL(file)
    }

    const deleteAttach = () => {
        setAttach(null)
        setFormData({
            ...formData,
            file: null
        })
    }

    return <Card flex onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        <Container width="12" fontWeight="bold" mx="16" fontSize="22px" children={<TextLocalized children="msgTournamentTitle" />} />
        <Container width="12" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgName"} placeholder={dictionary["msgName"]} type="text" name="identifier" value={formData.identifier} />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgAddress"} placeholder={dictionary["msgAddress"]} type="text" name="location" value={formData.location} />} />
        <Container width="6" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgDate"} placeholder={dictionary["msgDate"]} type="date" name="startDate" value={formData.startDate ? format(new Date(formData.startDate), 'yyyy-MM-dd') : ""} />} />

        <Container width="6" ph="16" pb="16">
            <Card width="12" px="16" color="color-white" border="solid" borderWidth="1px" borderColor="color-black">
                <select onChange={onSelectChange} name="sport" value={formData.sport ? formData.sport.id : ""}>
                    <option value="" children="Deporte" />
                    {sportList.map(sport => <option key={sport.id} value={sport.id} children={sport.description} /> )}                    
                </select>
            </Card>
        </Container>

        <Container width="12" px="16" children={<TextLocalized children={"msgDescription"} />} />
        <Container width="12" ph="16" pb="16" children={<AreaInput onChange={onChange} titleph="16" title={"msgCompleteHere"} placeholder={dictionary["msgCompleteHere"]} name="description" value={formData.description} />} />

        <ContainerFlex width="12" ph="16" align="center">
            <Container pv="16" children={<TextLocalized children="msgSelectFile" />} />
            {
                formData.file || attach
                    ? <Button onClick={deleteAttach} mh="16" ph="16" pv="8" justify="center" align="center" color="color-black" fontColor="color-white" letterSpacing="1px" children={<TextLocalized children="actionDelete" />} />
                    : <Button onClick={() => inputRef.current.click()} mh="16" ph="16" pv="8" justify="center" align="center" color="color-white" fontColor="color-black" letterSpacing="1px" children={<TextLocalized children="actionSelect" />} />
            }
            <input onChange={handleImageChange} ref={inputRef} hidden type="file" />
        </ContainerFlex>
        <ButtonFlat isSending={isSending} width="wrap" mx="16" action={sendRequest} label="actionSave" fontColor="color-white" />
    </Card>
}

const TournamentItem = ({ item, showItem, editItem, deleteItem }) => {
    return <ContainerFlex width="12" px="16" align="center">
        <Container width="wrap">
            <Container width="12" fontWeight="bold" children={item.identifier} />
            <Container width="12" fontSize="12px" children={item.location} />
        </Container>
        <Button onClick={() => showItem(item)} px="12" mr="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionShow" />} />
        <Button onClick={() => editItem(item)} px="12" mh="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionEdit" />} />
        <Button onClick={() => deleteItem(item)} px="12" ml="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionDelete" />} />
    </ContainerFlex>
}

const TournamentsView = () => {
    const [items, setItems] = useState([]);
    const { dispatch } = useContext(UserContext);

    useEffect(() => getItems(), [])

    const getItems = () => HttpConnection(HttpOperations.tournament, result => setItems(result), error => console.log(error), HttpMethod.get);

    const showItem = (item) => {
        if (item.file)
            HttpImage(`${HttpOperations.file}/${item.file.id}/file`,
                result => dispatch({ action: UserAction.showModal, data: { show: true, body: <img alt="document" height="95%" src={result} /> } }),
                error => console.log(error));
    }

    const editIitem = (item) => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <TournamentForm refreshItems={getItems} item={item} /> } });
    }

    const addItem = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <TournamentForm refreshItems={getItems} item={{ ...tournamentDefault }} /> } });
    }

    const deleteItem = (item) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.tournament}/${item.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "msgDeleteSuccess" } });
                getItems();
            },
            error => console.log(error), HttpMethod.delete)
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    return (
        <ContainerFlex width="12" align="center" justify="center" direction="column" px="16">
            <ContainerFlex width="12" lg="6" align="center">
                <Container width="wrap" fontWeight="bold" mv="16" fontSize="32px" children={<TextLocalized children="msgTournamentList" />} />
                <Button onClick={addItem} px="12" color="color-black" fontWeight="500" fontSize="12px" fontColor="color-white" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionCreateInstallation" />} />
            </ContainerFlex>
            <Card width="12" lg="6" mv="8" color="color-gray-card">
                {items.map((item, index) => <TournamentItem key={index} item={item} showItem={showItem} editItem={editIitem} deleteItem={deleteItem} />)}
            </Card>
        </ContainerFlex>
    );
};

export default TournamentsView;
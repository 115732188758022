import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';

import { addDays, addHours, addMinutes, format, parse } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Button, Card, Container, ContainerFlex, CustomInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { ModalViewDefault } from '../components/util/ModalUtil';
import { bookingStates, sportList } from '../constants/model';

const BookForm = ({ item, refreshItems }) => {
    const [formData, setFormData] = useState(item);
    const [userFilter, setUserFilter] = useState({ phone: "", date: "", time: "", sport: "" });

    const [users, setUsers] = useState([]);
    const [trackSelected, setTrackSelected] = useState();
    const [tracks, setTracks] = useState([]);
    const [timetable, setTimetable] = useState([]);
    const [inCash, setInCash] = useState(false);

    const [filterIsSending, setFilterIsSending] = useState(false);
    const [createIsSending, setCreateIsSending] = useState(false);

    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);

    const onChangeFilters = (e) => setUserFilter({ ...userFilter, [e.target.name]: e.target.value });
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendFilter = () => {
        if (userFilter.phone !== "" && userFilter.date !== "" && userFilter.time !== "" && userFilter.sport != "") {
            setFilterIsSending(true);
            HttpConnection(`${HttpOperations.user}/page?page=0&count=10&columns=type,phone&values=2,${userFilter.phone}`,
                response => {
                    setFilterIsSending(false)
                    setUsers(response)
                }, error => {
                    setFilterIsSending(false)
                    console.log(error)
                }, HttpMethod.get);

            HttpConnection(`${HttpOperations.track}/available?idSport=${userFilter.sport}&startDate=${userFilter.date}&startTime=${userFilter.time}:00&latitude=46&longitude=-4&endTime=00:00:00&radio=2000000`,
                response => {
                    setFilterIsSending(false)
                    setTracks(response)
                }, error => {
                    setFilterIsSending(false)
                    console.log(error)
                }, HttpMethod.get);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    useEffect(() => {
        if (formData.track && userFilter.date) {
            let dateNext = addDays(parse(userFilter.date, "dd/MM/yyyy", new Date()), 1)
            //Get track details
            HttpConnection(`${HttpOperations.track}/${formData.track}`, response => setTrackSelected(response), error => console.log(error), HttpMethod.get);
            //Get timetable
            HttpConnection(`${HttpOperations.track}/timetable?idTrack=${formData.track}&startDate=${userFilter.date}&endDate=${format(dateNext, "dd/MM/yyyy")}`,
                response => {
                    setTimetable(response.days[0].hours)
                }, error => console.log(error), HttpMethod.get);
        }
    }, [formData.track])

    const getPrice = (startTime, idDay) => {
        let timeAux = parse(`${startTime}`, 'HH:mm', new Date());
        idDay = idDay < 7 ? (idDay + 1) : 1;
        //Filter available prices by day
        let scheduleFilter = trackSelected.schedule.filter(sch => sch.idDay === idDay);

        let price = 0;
        //More one price by day
        if (scheduleFilter.length > 1) {
            //Find correct price
            scheduleFilter.forEach(val => {
                if (timeAux >= val.startTime && timeAux <= val.endTime)
                    price = val.price
            });
        } else if (scheduleFilter.length == 1) {
            price = scheduleFilter[0].price;
        }
        return price;
    }

    const sendRequest = () => {
        if (formData.track && formData.user && formData.startTime && trackSelected) {

            //Set flag
            setCreateIsSending(true);

            let dateAux = parse(`${userFilter.date} ${formData.startTime}`, 'dd/MM/yyyy HH:mm', new Date());
            let formDataAux = {
                identifier: `${Math.floor(100000 + Math.random() * 900000)}`,
                track: { id: formData.track },
                user: { id: formData.user },
                startTime: dateAux.getTime(),
                price: getPrice(formData.startTime, dateAux.getDay()),
                participants: userFilter.sport === 1 ? 22
                    : userFilter.sport === 2 ? 10
                        : userFilter.sport === 3 ? 4
                            : userFilter.sport === 4 ? 4
                                : userFilter.sport === 6 ? 14
                                    : userFilter.sport === 7 ? 10
                                        : 1,
            }

            if (inCash) {
                formDataAux.status = { id: 8 }
            }

            console.log(formDataAux)

            HttpConnection(`${HttpOperations.booking}`,
                result => {
                    //Refresh data
                    refreshItems();
                    //Notify user
                    dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
                },
                error => {
                    setCreateIsSending(false);
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } })
                },
                HttpMethod.post, formDataAux);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return <Card onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        <Container fontWeight="bold" mx="16" fontSize="22px" children={<TextLocalized children="msgBookTitle" />} />

        <Container fontWeight="bold" mx="16" fontSize="14px" children={<TextLocalized children="msgBookFilter" />} />
        <ContainerFlex ph="16" pb="16">
            <CustomInput onChange={onChangeFilters} titleph="16" title={"msgUserPhone"} placeholder={dictionary["msgUserPhone"]} type="phone" name="phone" value={userFilter.phone} />
        </ContainerFlex>
        <ContainerFlex ph="16" pb="16">
            <CustomInput onChange={e => setUserFilter({ ...userFilter, date: format(new Date(e.target.value), "dd/MM/yyyy") })} width="wrap" titlemh="16" inputmr="8" title={"msgBookDate"} placeholder={dictionary["msgBookDate"]} type="date" name="date" value={userFilter.date ? format(parse(userFilter.date, "dd/MM/yyyy", new Date()), "yyyy-MM-dd") : ""} />
            <CustomInput onChange={onChangeFilters} width="wrap" titlemh="16" inputmr="8" title={"msgBookTime"} placeholder={dictionary["msgBookTime"]} type="time" name="time" value={userFilter.time} />
        </ContainerFlex>

        <ContainerFlex ph="16" pb="16">
            <Card width="12" px="16" color={userFilter.sport == "" ? "color-gray" : "color-white"} border="solid" borderWidth="1px" borderColor="color-black">
                <select onChange={onChangeFilters} name="sport" value={userFilter.sport}>
                    <option value="" children="Deporte" />
                    {sportList.map(sport => <option key={sport.id} value={sport.id} children={sport.description} />)}
                </select>
            </Card>
        </ContainerFlex>

        <ButtonFlat isSending={filterIsSending} mx="16" width="auto" action={sendFilter} label="actionFilter" fontColor="color-white" />

        <Container fontWeight="bold" mx="16" fontSize="14px" children={<TextLocalized children="msgBookData" />} />

        <ContainerFlex width="12" ph="16" pb="16">
            <Card width="12" px="16" color={!formData.user ? "color-gray" : "color-white"} border="solid" borderWidth="1px" borderColor="color-black">
                <select onChange={onChange} name="user" value={formData.user}>
                    <option value="" children="Usuario" />
                    {users.map(user => <option key={user.id} value={user.id} children={user.fullname} />)}
                </select>
            </Card>
        </ContainerFlex>

        <ContainerFlex width="12" ph="16" pb="16">
            <Card width="12" px="16" color={!formData.track ? "color-gray" : "color-white"} border="solid" borderWidth="1px" borderColor="color-black">
                <select onChange={onChange} name="track" value={formData.track}>
                    <option value="" children="Pista" />
                    {tracks.map(track => <option key={track.id} value={track.id} children={`${track.installation.identifier} - ${track.description}`} />)}
                </select>
            </Card>
        </ContainerFlex>
        {
            formData.track ?
                <ContainerFlex width="12" ph="16" pb="16">
                    <Card width="12" px="16" color={!formData.startTime ? "color-gray" : "color-white"} border="solid" borderWidth="1px" borderColor="color-black">
                        <select onChange={onChange} name="startTime" value={formData.startTime}>
                            <option value="" children="Hora" />
                            {timetable.filter(hour => hour.status === 1).map((hour, index) => <option key={index} value={hour.id} children={`${hour.startTime}`} />)}
                        </select>
                    </Card>
                </ContainerFlex>
                : null
        }
        <Container width="12" ph="16">
            <Button onClick={() => setInCash(!inCash)} flex align="center">
                <Card flex justify="center" align="center" mr="16" minwidth="24px" height="24px" color={inCash ? "color-black" : "color-white"} border="solid" borderColor="color-black"
                    borderWidth="1px" fontColor="color-white">
                    <Icon path={mdiCheck} size="20px" />
                </Card>
                Pago en efectivo
            </Button>
        </Container>
        <ButtonFlat isSending={createIsSending} mx="16" width="auto" action={sendRequest} label="actionSave" fontColor="color-white" />
    </Card>
}

const BookItem = ({ item, editItem, deleteItem }) => {
    return <ContainerFlex width="12" px="16" align="center">
        <Container width="wrap">
            <Container width="12" fontWeight="bold" children={item.identifier} />
            <Container width="12" fontSize="12px" children={`${item.track.identifier} - ${item.track.installation.identifier}`} />
            <Container width="12" fontSize="12px" children={`${format(new Date(item.startTime), 'dd/MM/yyyy')} ${format(new Date(item.startTime), 'HH:mm')} - ${format(addMinutes(new Date(item.startTime), item.track.duration), 'HH:mm')}`} />
            <Container width="12" fontSize="12px" children={`${item.user.fullname} - ${item.user.phone}`} />
            <Container width="12" fontSize="12px" fontWeight="bold" children={`${item.status.description}`} />
        </Container>

        <Button display="none" onClick={() => editItem(item)} px="12" mr="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionEdit" />} />
        {
            item.status.id < 3 || item.status.id === 8 ?
                <Button onClick={() => deleteItem(item)} px="12" ml="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionCancel" />} />
                : null
        }
    </ContainerFlex>
}

const BooksView = () => {
    const [items, setItems] = useState([]);
    const [userFilter, setUserFilter] = useState({ start: "", end: "", type: "" });
    const { dispatch } = useContext(UserContext);
    const { dictionary } = useContext(LanguageContext);

    useEffect(() => getItems(), [])

    const getItems = () => HttpConnection(HttpOperations.booking, result => setItems(result), error => console.log(error), HttpMethod.get);

    const editItem = (item) => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <BookForm refreshItems={getItems} item={item} /> } });
    }

    const addItem = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <BookForm refreshItems={getItems} item={{ identifier: "" }} /> } });
    }

    const deleteItem = (item) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.booking}/${item.id}/cancel`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "msgDeleteSuccess" } });
                getItems();
            },
            error => console.log(error), HttpMethod.patch)
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    const getItemsFiltered = () => {
        try {
            let itemAux = [...items];

            if (userFilter.type !== "") {
                itemAux = itemAux.filter(book => book.status.id == userFilter.type);
            }

            if (userFilter.start !== "") {
                const startDate = parse(userFilter.start, "dd/MM/yyyy", new Date())
                itemAux = itemAux.filter(book => {
                    console.log(book.startTime)
                    console.log(startDate.getTime())
                    return book.startTime >= startDate.getTime()
                });
            }

            if (userFilter.end !== "") {
                const endDate = parse(userFilter.end, "dd/MM/yyyy", new Date())
                endDate.setHours(23, 59, 59)
                itemAux = itemAux.filter(book => book.startTime <= endDate.getTime());
            }

            return itemAux;
        } catch (e) {
            console.log(e);
            return items;
        }
    }

    const onChangeDate = (e) => {
        try {
            setUserFilter({ ...userFilter, [e.target.name]: format(new Date(e.target.value), "dd/MM/yyyy") })
        } catch(exp) {
            setUserFilter({ ...userFilter, [e.target.name]: "" })
            console.log(exp)
        }
    }
 
    return (
        <ContainerFlex width="12" align="center" justify="center" direction="column" px="16">
            <ContainerFlex width="12" lg="6" align="center">
                <Container width="wrap" fontWeight="bold" mv="16" fontSize="32px" children={<TextLocalized children="msgBookList" />} />
                <Button onClick={addItem} px="12" color="color-black" fontWeight="500" fontSize="12px" fontColor="color-white" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionCreateInstallation" />} />
            </ContainerFlex>
            <ContainerFlex width="12" lg="6" mb="16">
                <CustomInput onChange={onChangeDate} width="wrap" titlemh="16" inputmr="8" title={"msgDateStart"} placeholder={dictionary["msgDateStart"]} type="date" name="start" value={userFilter.start ? format(parse(userFilter.start, "dd/MM/yyyy", new Date()), "yyyy-MM-dd") : ""} />
                <CustomInput onChange={onChangeDate} width="wrap" titlemh="16" inputmr="8" title={"msgDateEnd"} placeholder={dictionary["msgDateEnd"]} type="date" name="end" value={userFilter.end ? format(parse(userFilter.end, "dd/MM/yyyy", new Date()), "yyyy-MM-dd") : ""} />
                <Card width="wrap" px="16" color={!userFilter.type === "" ? "color-gray" : "color-white"} border="solid" borderWidth="1px" borderColor="color-black">
                    <select onChange={e => setUserFilter({ ...userFilter, type: e.target.value })} name="type" value={userFilter.type}>
                        <option value="" children="Estado" />
                        {bookingStates.map( (bookingState, index) => <option key={index} value={index + 1} children={`${bookingState}`} />)}
                    </select>
                </Card>
            </ContainerFlex>
            <Card width="12" lg="6" mv="8" color="color-gray-card">
                {getItemsFiltered().sort((a, b) => b.startTime - a.startTime).map((item, index) => <BookItem key={index} item={item} editItem={editItem} deleteItem={deleteItem} />)}
            </Card>
        </ContainerFlex>
    );
};

export default BooksView;
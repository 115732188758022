import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Button, Card, Container, ContainerFlex, CustomInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { ModalViewDefault } from '../components/util/ModalUtil';

const NotificationForm = ({ item, refreshItems }) => {
    const [formData, setFormData] = useState(item);
    const [isSending, setIsSending] = useState(false);

    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.title !== "" && formData.body !== "") {
            setIsSending(true);
            HttpConnection(`${HttpOperations.alert}${formData.id ? `/${formData.id}` : ''}`,
                result => {
                    //Refresh data
                    refreshItems();
                    //Hide popup
                    dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
                    //Notify user
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
                },
                error => {
                    setIsSending(false)
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } })
                },
                formData.id ? HttpMethod.put : HttpMethod.post, formData);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return <Card onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        <Container fontWeight="bold" mx="16" fontSize="22px" children={<TextLocalized children="msgNotificationTitle" />} />
        <Container ph="16" pb="16" pv="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgTitle"} placeholder={dictionary["msgTitle"]} type="text" name="title" value={formData.title} />} />
        <Container ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgDescription"} placeholder={dictionary["msgDescription"]} type="text" name="body" value={formData.body} />} />

        <ButtonFlat isSending={isSending} mx="16" width="auto" action={sendRequest} label="actionSave" fontColor="color-white" />
    </Card>
}

const NotificationItem = ({ item, editItem, deleteItem }) => {
    return <ContainerFlex width="12" px="16" align="center">
        <Container width="wrap">
            <Container width="12" fontWeight="bold" children={item.title} />
            <Container width="12" mb="8" fontSize="12px" children={item.body} />
            <Container width="12" fontSize="12px" children={format(new Date(item.sendDate), 'dd/MM/yyyy HH:mm')} />
        </Container>
        <Button display="none" onClick={() => editItem(item)} px="12" mr="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionEdit" />} />
        <Button onClick={() => deleteItem(item)} px="12" ml="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionDelete" />} />
    </ContainerFlex>
}

const NotificationsView = () => {
    const [items, setItems] = useState([]);
    const { dispatch } = useContext(UserContext);

    useEffect(() => getItems(), [])

    const getItems = () => HttpConnection(HttpOperations.alert, result => setItems(result.filter(alertItem => alertItem.idAlertType == 1)), error => console.log(error), HttpMethod.get);

    const editIitem = (item) => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <NotificationForm refreshItems={getItems} item={item} /> } });
    }

    const addItem = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <NotificationForm refreshItems={getItems} item={{ identifier: "" }} /> } });
    }

    const deleteItem = (item) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.alert}/${item.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "msgDeleteSuccess" } });
                getItems();
            },
            error => console.log(error), HttpMethod.delete)
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    return (
        <ContainerFlex width="12" align="center" justify="center" direction="column" px="16">
            <ContainerFlex width="12" lg="6" align="center">
                <Container width="wrap" fontWeight="bold" mv="16" fontSize="32px" children={<TextLocalized children="msgNotificationList" />} />
                <Button onClick={addItem} px="12" color="color-black" fontWeight="500" fontSize="12px" fontColor="color-white" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionCreateInstallation" />} />
            </ContainerFlex>
            <Card width="12" lg="6" mv="8" color="color-gray-card">
                {items.map((item, index) => <NotificationItem key={index} item={item} editItem={editIitem} deleteItem={deleteItem} />)}
            </Card>
        </ContainerFlex>
    );
};

export default NotificationsView;
import React from 'react';
import { Button, SpinnerLoader, TextLocalized } from './styled/Styled';

const ButtonFlat = ({ disabled = false, isSending = false, label, action, color = "color-black", mt, ...rest }) => {
    return disabled
        ? <Button disabled flex justify="center" align="center" width="12" mt={mt} mb="8" height="54px"
            border="solid" borderWidth="1px" borderColor={"color-gray-dark-circle"} fontColor="color-gray-dark-circle" fontWeight="400" fontSize="13px" radius="12px"
            letterSpacing="2px"
            children={<TextLocalized children={label} />} />
        : isSending ?
            <Button flex justify="center" align="center" width="12" mt={mt} mb="8" height="54px"
                border="solid" borderWidth="1px" borderColor={color} color={color} fontWeight="400" fontSize="13px" radius="12px"
                letterSpacing="2px" {...rest}
                children={<SpinnerLoader />} />
            : <Button onClick={action} flex justify="center" align="center" width="12" mt={mt} mb="8" height="54px"
                border="solid" borderWidth="1px" borderColor={color} color={color} fontWeight="400" fontSize="13px" radius="12px"
                letterSpacing="2px" {...rest}
                children={<TextLocalized children={label} />} />
};

export default ButtonFlat;
import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { Fragment } from 'react/cjs/react.production.min';
import { UserAction, UserContext } from './providers/UserProvider';
import { Button, Container, ContainerFlex, TextLocalized } from './styled/Styled';

const Header = () => {
    let history = useNavigate();
    let { state, dispatch } = useContext(UserContext);

    return (
        <ContainerFlex width="12" color="color-primary" fontColor="color-white" align="center" justify="space-between">
            <Container fontWeight="600" px="16" children="ReseSport Dashboard" />
            <ContainerFlex align="center">
                {
                    state.user.usuario ?
                        <Fragment>
                            <Button onClick={() => history("/")} mh="4" px="16" textAlign="center" children={<TextLocalized children="msgInstallations" />} />
                            <Button onClick={() => history("/tracks")} mh="4" px="16" textAlign="center" children={<TextLocalized children="msgTracks" />} />
                            <Button onClick={() => history("/books")} mh="4" px="16" textAlign="center" children={<TextLocalized children="msgBooks" />} />
                            {
                                state.user.usuario.type.id === 0 ?
                                    <Fragment>
                                        <Button onClick={() => history("/users")} mh="4" px="16" textAlign="center" children={<TextLocalized children="msgUsers" />} />
                                        <Button onClick={() => history("/managers")} mh="4" px="16" textAlign="center" children={<TextLocalized children="msgManagers" />} />
                                        <Button onClick={() => history("/products")} mh="4" px="16" textAlign="center" children={<TextLocalized children="msgProducts" />} />
                                        <Button onClick={() => history("/tournaments")} mh="4" px="16" textAlign="center" children={<TextLocalized children="msgTournaments" />} />
                                        <Button onClick={() => history("/notifications")} mh="4" px="16" textAlign="center" children={<TextLocalized children="msgNotifications" />} />
                                    </Fragment>
                                    : null
                            }
                        </Fragment>
                        : null
                }
                <Button onClick={() => dispatch({ action: UserAction.logout })} mh="4" ph="16" pv="4" color="color-black" fontColor="color-white" textAlign="center" children={<TextLocalized children="actionExit" />} />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Header;